/* Mobile Preview Contact Form Dialog Styles */
.preview-mobile-contact-dialog {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--card-background);
  border: none;
  padding: 0;
  margin: 0;
  border-radius: inherit;
}

.preview-mobile-contact-dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.preview-mobile-contact-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(to bottom, #1a1a2e, #16213e);
}

.preview-mobile-contact-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 188, 212, 0.2);
  position: relative;
  background: rgba(0, 0, 0, 0.3);
}

.preview-mobile-contact-close {
  position: absolute;
  left: 16px;
  background: none;
  border: none;
  color: var(--text-color);
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.preview-mobile-contact-close:hover {
  background: rgba(255, 255, 255, 0.1);
}

.preview-mobile-contact-close-icon {
  width: 16px;
  height: 16px;
}

.preview-mobile-contact-title {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  margin: 0;
  color: var(--text-color);
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 188, 212, 0.5);
}

.preview-mobile-contact-content {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.preview-mobile-contact-description {
  color: var(--text-color);
  opacity: 0.8;
  margin-bottom: 20px;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.5;
}

.preview-mobile-contact-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.preview-mobile-contact-form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.preview-mobile-contact-label {
  color: var(--text-color);
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.preview-mobile-contact-required {
  color: #ff4d4f;
}

.preview-mobile-contact-input,
.preview-mobile-contact-textarea {
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 188, 212, 0.3);
  border-radius: 6px;
  color: var(--text-color);
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.preview-mobile-contact-input:focus,
.preview-mobile-contact-textarea:focus {
  outline: none;
  border-color: var(--accent-blue);
  box-shadow: 0 0 0 2px rgba(0, 188, 212, 0.1);
  background: rgba(0, 0, 0, 0.3);
}

.preview-mobile-contact-textarea {
  resize: vertical;
  min-height: 80px;
  max-height: 160px;
}

.preview-mobile-contact-submit {
  background: var(--accent-blue);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 8px;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 0 15px rgba(0, 188, 212, 0.3);
}

.preview-mobile-contact-submit:hover {
  background: rgba(0, 188, 212, 0.9);
  transform: translateY(-1px);
  box-shadow: 0 0 20px rgba(0, 188, 212, 0.4);
}

.preview-mobile-contact-submit:active {
  transform: translateY(0);
}

/* Update phone content to handle dialog positioning */
.phone-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  color: var(--text-color);
  padding: 40px 20px 20px;
}

.mobile-frame {
  height: 100%
}

.mobile-form-description {
  color: white;
}
