@media only screen and (max-width: 992px) {
    .afterAuth {
        display: block;
        /*padding: 0 30px;*/
    }
}

@media only screen and (min-width: 992px) {
    .afterAuth {
        display: flex;
        flex-direction: row;
        height: 100vh
    }
    .iconText {
        display: inline;
        padding-left: 8px;
    }
}