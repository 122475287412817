.calendar-carousel i {
    margin-top: 60px;
}

.eventForm input, .eventForm select {
    background-color: #2c2c2c;
    color: #ffffff;
}
.eventDialogBackground {
    background-color: #000000;
    border-bottom: none !important;
    border-top: none !important;
}
.form-group {
    background-color: #2c2c2c;
    padding: 15px;
}


/* for event transitions */
.eventTransition-enter {
    opacity: 0.01;
    transform: scale(1.1);
}

.eventTransition-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms;
}

.eventTransition-exit {
    opacity: 1;
    transform: scale(1);
}

.eventTransition-exit-active {
    opacity: 0.01;
    transform: scale(1.1);
    transition: all 300ms;
}

/* for event transitions */
.removedTours-exit {
    opacity: 1;
    transform: scale(1);
}
.removedTours-exit-active {
    opacity: 0.01;
    transform: scale(0.5);
    transition: all 300ms;
}


/* react-datepicker */
.react-datepicker {
    font-size: 75%
}


.submitButton {
    background-color: transparent;
    padding: 10px 30px;
    color: #ff44a4;
    border: 1px solid #ff44a4;
}