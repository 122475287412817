.nav-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: white;
}

.nav-item {
  color: white;
  padding: 10px;
  cursor: pointer;
}

.nav-item.download {
  background-color: #fbb655;
}

.table {
  margin-top: 20px;
  
}

.header {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  background-color: white;
  margin-bottom: 10px;
  
  
}

#contactTable .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border-bottom: 1px solid #ddd; */
 
}

.cell, .header-cell {
  flex: 1; /* Make sure cells take up equal space */
  padding: 12px 8px;
  text-align: left;
  
}

.header-cell {
  background-color: #f2f2f2;
  font-weight: bold;
  width: 150px;
}

.cell {
  color: rgb(220, 220, 220);
  background-color: #444444;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  vertical-align: middle;
  text-align: left;
  width: 100px;

}

.button-row {
  background-color: rgb(29 30 30);
  /* border-radius: 1rem; */
  color: #FFFFFF;
  padding: 5px;
  transition: background-color 0.4s ease; /* Smooth transition effect */
  margin-bottom: 10px;
}
.button-row:hover {
  background-color: #8627df; /* Change this to the desired hover color */
  box-shadow: 5px 5px 15px #8627df, -5px -5px 15px #8627df;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .header-cell:nth-child(4),
  .header-cell:nth-child(5),
  .header-cell:nth-child(6),
  .header-cell:nth-child(7),
  .cell:nth-child(4),
  .cell:nth-child(5),
  .cell:nth-child(6),
  .cell:nth-child(7) {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .header-cell:nth-child(4),
  .header-cell:nth-child(5),
  .header-cell:nth-child(6),
  .header-cell:nth-child(7),
  .cell:nth-child(4),
  .cell:nth-child(5),
  .cell:nth-child(6),
  .cell:nth-child(7) {
    display: none;
  }
}