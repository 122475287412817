.engagement {
    position: relative;

    .chart {
        width: 70%;
    }
    
    .highlight {
        position: absolute;
        display: table;
        right: 25px;
        top: 25px;
        text-align: center;
        height: 50px;
        opacity: 0.8;
        box-shadow: 0 0 3px 0 #26cad3;
        border: solid 0.3px #26cad3;
        background-color: rgba(38, 202, 211, 0.08);
        padding: 20px;

        .details {
            display: table-cell;
            vertical-align: middle;

            .date {
                color: #ff8200;
            }

            .open-social-media {
                text-transform: none;
                margin-left: 20px;
                display: inline-block;
                color: #ffffff;
            }
        }
    }
}