.add-event-dialog {
  background-color: #0f0f1a;
  color: #e0e0e0;
  border: 1px solid #ff7043;
  border-radius: 8px;
  padding: 24px;
  max-width: 400px;
  width: 100%;
  font-family: "Inter", sans-serif;
  box-shadow: 0 0 20px rgba(255, 112, 67, 0.3);
  overflow: visible;
  position: relative;
}

.add-event-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
}

.add-event-dialog h2 {
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ff7043;
  text-shadow: 0 0 10px rgba(255, 112, 67, 0.5);
}

.add-event-form-group {
  margin-bottom: 16px;
}

.add-event-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #ff7043;
  font-weight: 500;
}

.add-event-form-group input[type="text"],
.add-event-form-group input[type="url"],
.add-event-form-group input[type="datetime-local"] {
  width: 100%;
  padding: 10px;
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  color: #e0e0e0;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.add-event-form-group input[type="text"]:focus,
.add-event-form-group input[type="url"]:focus,
.add-event-form-group input[type="datetime-local"]:focus {
  border-color: #ff7043;
  box-shadow: 0 0 0 2px rgba(255, 112, 67, 0.2);
  outline: none;
}

.add-event-form-group.checkbox {
  display: flex;
  align-items: center;
}

.add-event-form-group.checkbox input[type="checkbox"] {
  margin-right: 8px;
}

.add-event-form-group.checkbox label {
  margin-bottom: 0;
}

.add-event-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.add-event-cancel-button,
.add-event-save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.add-event-cancel-button {
  background-color: #2a2a3a;
  color: #e0e0e0;
}

.add-event-save-button {
  background-color: #ff7043;
  color: #0f0f1a;
}

.add-event-cancel-button:hover,
.add-event-save-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.add-event-error {
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.add-event-dialog-title {
  font-size: 20px;
}

.add-event-date-time-picker {
  width: 100%;
}

#date-time-picker-root .MuiPaper-root {
 background-color: #ff7043;
}

#date-time-picker-root ~ .MuiFormControl-root .MuiInputAdornment-root .MuiIconButton-root {
  color: white;
}

.add-event-dialog-title {
  font-size: 22px;
  margin: 0 !important;
}

.add-event-dialog-header {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  position: relative;
}

.add-event-dialog-close-button {
  position: absolute;
  right: 0;
}

.add-event-dialog-x {
  width: 24px;
  height: 24px;
}