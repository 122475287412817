.analytics-dialog {
  background-color: #0d0d0d;
  color: #e0e0e0;
  border: 1px solid #2a2a3a;
  border-radius: 12px;
  padding: 24px;
  max-width: 600px;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.analytics-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
}

.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.analytics-header h2 {
  color: #00ffff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.analytics-dialog-close-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  transition: color 0.3s ease;
}

.analytics-dialog-close-button:hover {
  color: #fff;
}

.analytics-dialog-close-icon {
  width: 24px;
  height: 24px;
}

.time-range-selector {
  display: flex;
  background-color: #1a1a2e;
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 24px;
}

.time-range-selector button {
  flex: 1;
  background: none;
  border: none;
  color: #666;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.time-range-selector button.active {
  background-color: #fff;
  color: #0f0f1a;
}

.metrics-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.metric {
  background-color: #1a1a2e;
  border-radius: 8px;
  padding: 16px;
}

.metric h3 {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px;
  color: #666;
}

.metric .value {
  font-size: 32px;
  font-weight: 700;
}

.metric.clicks .value {
  color: #00ffff;
}

.metric.interactions .value {
  color: #ff00ff;
}

.chart-container {
  background-color: #1a1a2e;
  border-radius: 8px;
  padding: 16px;
}

