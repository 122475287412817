html {
    overflow-y: hidden;
}

body {
    font-size: 12px;
}

@media only screen and (max-width: 768px) {
    .knkt-large {
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    .knkt-xs {
        display: none;
    }
    .knkt-sm {
        display: none;
    }
    .knkt-md {
        display: block;
    }
    .knkt-lg {
        display: none;
    }
    .knkt-xl {
        display: none;
    }
    .knkt-large {
        display: block;
    }
    .afterAuth {
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .knkt-xs {
        display: none;
    }
    .knkt-sm {
        display: none;
    }
    .knkt-md {
        display: none;
    }
    .knkt-lg {
        display: block;
    }
    .knkt-large {
        display: block
    }
    .afterAuth {
        display: flex;
        flex-direction: row;
        height: 100vh
    }
    .iconText {
        display: inline;
        padding-left: 8px;
    }
}