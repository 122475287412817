@import '../../../../node_modules/include-media/dist/include-media';

@import '../../config';
@import '../../base';
@import '../../account';

.fans {
    @extend .font-info;

    margin: 0px 165px;
    text-transform: uppercase;

    @include media("≥phone", "≤tablet") {
        margin: 0 10px;
    }

    // a {
    //     color: $inactive-label-color;
    // }
    //-----------TimeLine Selector -- Remove these classs
    .btn-link {
        color: $inactive-label-color;
        text-transform: uppercase;
    }

    .btn:focus {
        outline: none;
    }

    .data-timeline {
        border-bottom: solid 1px white;
    }

    .btn.active {
        border-bottom: solid 2px white;
        color: $active-label-color;
    }

    //-----------TimeLine Selector -- Remove these classs

    .page-heading {
        display: flex;
        justify-content: space-between;

        div {
            width: calc(100%/3);
        }

        .media-title {
            text-align: center;
            font-size: 20px;
            letter-spacing: 3px;
        }
    }

    .fans-breadcrumb {
        display: inline-block;
        text-align: left;

        a {
            color: $inactive-label-color
        }
    }

    // .heading {
    //     color: $inactive-label-color;
    //     display: inline-block;
    //     width: 150px;
    //     margin-left: 10px;
    //     margin-top: 10px;
    //     border-top: solid 1px;
    //     border-left: solid 1px;
    //     border-right: solid 1px;
    //     text-align: center;
    //     padding: 10px;

    //     &.active {
    //         background-color: $selected-tab-bg-color;
    //         color: $active-label-color;
    //         border: 0;
    //         cursor: default;
    //     }
    // }

    // // Fix this class defn
    // .heading:first-child {
    //     margin-left: 0px;
    //     // border: 0;
    // }

    .tab-main-content {
        background-color: $selected-tab-bg-color;
        height: 400px;
        text-align: center;
        overflow: hidden;
    }

    /* can be named footer */
    /* has name clash with bootstrap */
    .view-also {
        margin-top: 15px;
        color: $inactive-label-color;

        ul {
            list-style: none;
            display: inline;
            padding-inline-start: 0px;

            li {
                display: inline;
                margin-left: 15px;

            }
        }
    }

    .chart-container {
        width: 50%;
    }
}