// Every thing under this file should be wrapped to a class named 'account'

.tab-heading {
    color: #a8a8a8;
    display: inline-block;
    width: 150px;
    margin-left: 10px;
    margin-top: 10px;
    border-top: solid 1px;
    border-left: solid 1px;
    border-right: solid 1px;
    border-bottom: 0;
    text-align: center;
    padding: 10px;

    &.active {
        background-color: $selected-tab-bg-color;
        color: $active-label-color;
        border: 0;
        cursor: default;
    }
}

// Fix this class defn
.tab-heading:first-child {
    margin-left: 0px;
    // border: 0;
}