.delete-block-modal {
  background-color: #0d0d0d;
  color: #e0e0e0;
  border: 1px solid #5e0b0b;
  border-radius: 8px;
  padding: 24px;
  max-width: 400px;
  width: 100%;
  font-family: "Inter", sans-serif;
  box-shadow: 0 0 20px rgba(171, 71, 188, 0.3);
}

.delete-block-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
}

.delete-block-modal h2 {
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #5e0b0b;
  text-shadow: 0 0 10px rgba(171, 71, 188, 0.5);
}

.delete-modal-form-group {
  margin-bottom: 20px;
}

.delete-modal-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #5e0b0b;
  font-weight: 500;
}

.delete-modal-form-group textarea {
  width: 100%;
  padding: 12px;
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  color: #e0e0e0;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  transition: all 0.3s ease;
  resize: vertical;
}

.delete-modal-form-group textarea:focus {
  border-color: #5e0b0b;
  box-shadow: 0 0 0 2px rgba(171, 71, 188, 0.2);
  outline: none;
}

.delete-modal-form-group textarea::placeholder {
  color: #6c6c8b;
}

.delete-modal-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.delete-modal-no-button,
.delete-modal-yes-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: none;
}

.delete-modal-no-button {
  background-color: #2a2a3a;
  color: #e0e0e0;
}

.delete-modal-yes-button {
  background-color: #5e0b0b;
  color: #0f0f1a;
}

.delete-modal-no-button:hover,
.delete-modal-yes-button:hover:not(:disabled) {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.delete-modal-yes-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.delete-block-modal-title {
  font-size: 22px;
}

.delete-block-modal-title {
  font-size: 22px;
  margin: 0 !important;
}

.delete-block-modal-header {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  position: relative;
}

.delete-block-modal-close-button {
  position: absolute;
  right: 0;
}

.delete-block-modal-x {
  width: 24px;
  height: 24px;
}

.delete-modal-content {
  font-size: 15px;
}