/* Cyberpunk Theme Variables */
:root {
  --background: #0a0a0f;
  --card-background: #12121a;
  --card-background-hover: #1a1a24;
  --text-primary: #e0e0ff;
  --text-secondary: #a0a0c0;
  --text-muted: #6a6a8c;
  --border-color: #2a2a3a;
  --border-glow: rgba(128, 0, 255, 0.3);

  /* Neon accent colors */
  --accent-blue: #00ccff;
  --accent-purple: #9933ff;
  --accent-pink: #ff33cc;
  --accent-green: #33ff99;

  /* Module type colors */
  --link-color: #00ccff;
  --text-color: #9933ff;
  --contact-color: #33ff99;
  --event-color: #ff6633;

  /* Shadows */
  --neon-shadow: 0 0 10px rgba(0, 204, 255, 0.5);
  --card-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);

  /* Glow effect variables */
  --glow-color: #113322;
  --glow-spread-color: rgba(14, 45, 29, 0.3);
  --enhanced-glow-color: rgba(7, 28, 17, 0.6);
  --btn-color: rgba(6, 6, 8, 0.5);
}

/* Main Layout */
.page-wrapper {
  padding: 16px;
  height: calc(100vh - 50px);
}

/* Customer URL Input Styling */
.customer-url-container {
  display: flex;
  align-items: center;
  background-color: var(--card-background);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 10px 16px;
  margin-bottom: 20px;
  box-shadow: var(--card-shadow);
}

.customer-url-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--accent-blue);
  font-weight: 500;
  min-width: 120px;
}

.customer-url-input {
  flex: 1;
  background: transparent;
  border: none;
  color: var(--text-primary);
  font-size: 14px;
  padding: 8px;
  outline: none;
  border-bottom: 1px solid var(--border-color);
  transition: border-color 0.3s ease;
}

.customer-url-input:focus {
  border-color: var(--accent-blue);
}

.customer-url-input::placeholder {
  color: var(--text-muted);
}

/* Customer URL Display in Preview */
.customer-url-display {
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid var(--border-color);
  width: 100%;
  justify-content: center;
}

.customer-url-text {
  font-size: 13px;
  color: var(--text-secondary);
  word-break: break-all;
}

.template-builder {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;
  height: calc(100% - 134px);
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .template-builder {
    grid-template-columns: 1fr 1fr;
  }
}

/* Header Styling */
.builder-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}

.title1 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(90deg, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 5px rgba(0, 204, 255, 0.3);
  letter-spacing: 1px;
}

.title2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(90deg, var(--accent-purple), var(--accent-pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 5px rgba(153, 51, 255, 0.3);
  letter-spacing: 0.5px;
  text-align: center;
}

/* Analytics Button */
.analytics-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  color: var(--accent-blue);
  border: 1px solid var(--accent-blue);
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 204, 255, 0.2);
}

.analytics-button:hover {
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.4);
  transform: translateY(-2px);
}

.analytics-button svg {
  filter: drop-shadow(0 0 2px var(--accent-blue));
}

/* Builder Left Section */
.builder-left {
  background: var(--card-background);
  border-radius: 12px;
  padding: 20px;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
}

.builder-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Button Grid */
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 20px;
}

/* Module Buttons */
.add-block-button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.add-block-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.05), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.add-block-button:hover::before {
  opacity: 1;
}

.add-block-button.link {
  border-color: var(--link-color);
  color: var(--link-color);
}

.add-block-button.text {
  border-color: var(--text-color);
  color: var(--text-color);
}

.add-block-button.contact {
  border-color: var(--contact-color);
  color: var(--contact-color);
}

.add-block-button.event {
  border-color: var(--event-color);
  color: var(--event-color);
}

.add-block-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.add-block-button.link:hover {
  box-shadow: 0 5px 15px rgba(0, 204, 255, 0.2);
}

.add-block-button.text:hover {
  box-shadow: 0 5px 15px rgba(153, 51, 255, 0.2);
}

.add-block-button.contact:hover {
  box-shadow: 0 5px 15px rgba(51, 255, 153, 0.2);
}

.add-block-button.event:hover {
  box-shadow: 0 5px 15px rgba(255, 102, 51, 0.2);
}

.plus-icon {
  width: 18px;
  height: 18px;
}

/* Blocks List */
.blocks-list {
  flex: 1;
  overflow-y: auto;
  padding-right: 5px;
}

.blocks-list::-webkit-scrollbar {
  width: 5px;
}

.blocks-list::-webkit-scrollbar-track {
  background: var(--card-background);
  border-radius: 10px;
}

.blocks-list::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 10px;
}

.blocks-list::-webkit-scrollbar-thumb:hover {
  background: var(--text-muted);
}

.blocks-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0;
}

/* Draggable Block */
.draggable-block {
  background: var(--card-background);
  border-radius: 10px;
  border: 1px solid var(--border-color);
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease;
  position: relative;
}

.phone-content .preview-block:hover::after {
  opacity: 1;
}

/* Block Actions */
.block-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border-right: 1px solid var(--border-color);
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  transition: all 0.2s ease;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-primary);
}

.action-button.edit:hover {
  color: var(--accent-blue);
}

.action-button.delete:hover {
  color: #ff3366;
}

.action-button.drag-handle {
  cursor: grab;
}

.action-button.drag-handle:hover {
  color: var(--accent-green);
}

/* Preview Pane */
.preview-pane {
  background: var(--card-background);
  border-radius: 12px;
  padding: 20px;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.preview-header {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

/* Phone Mockup */
.phone-mockup {
  position: relative;
  width: 280px;
  height: 580px;
  background: #000;
  border-radius: 36px;
  padding: 8px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5),
              0 0 0 1px rgba(255, 255, 255, 0.1),
              inset 0 0 0 2px rgba(255, 255, 255, 0.05);
  overflow: hidden;
  overflow-y: scroll;
}

.phone-mockup::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(45deg, var(--accent-blue), var(--accent-purple), var(--accent-pink), var(--accent-green));
  z-index: -1;
  border-radius: 37px;
  filter: blur(2px);
  opacity: 0.5;
}

.phone-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 25px;
  background: #000;
  border-radius: 0 0 16px 16px;
  z-index: 10;
}

.phone-content {
  background: #0f0f13;
  border-radius: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.phone-content::-webkit-scrollbar {
  width: 3px;
}

.phone-content::-webkit-scrollbar-track {
  background: transparent;
}

.phone-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

/* Profile Section */
.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  position: relative;
}

.avatar-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-blue);
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.3);
}

.avatar-fallback {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--text-primary);
}

.edit-bio-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: var(--card-background);
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(153, 51, 255, 0.3);
}

.edit-bio-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(153, 51, 255, 0.5);
}

.username {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px;
  color: white;
}

.user-bio {
  font-size: 14px;
  color: var(--text-secondary);
  text-align: center;
  max-width: 240px;
  line-height: 1.4;
  margin: 0 0 10px;
}

/* Social Icons */
.social-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.social-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--accent-blue);
  color: var(--accent-blue);
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 204, 255, 0.2);
}

.social-icon-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.4);
}

.add-social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px dashed var(--accent-purple);
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}

.add-social-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(153, 51, 255, 0.3);
}

/* Preview Blocks */
.preview-block {
  background: rgba(18, 18, 30, 0.8);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
  align-self: center;
  width: 100%;
  margin: 0 10px;
  min-height: fit-content;
}

.preview-block .ant-card-body {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.preview-block-desktop {
  margin-bottom: 0;
}

.preview-block-desktop:hover {
  border-color: var(--border-color);
  transform: none;
}

.preview-link {
  color: var(--link-color);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
}

.preview-link:hover {
  text-shadow: 0 0 8px rgba(0, 204, 255, 0.5);
}

.preview-text {
  color: var(--text-primary);
  margin-bottom: 0;
}

.preview-text-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.block-avatar {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), transparent);
  border: 1px solid var(--border-color);
  border-radius: 8px !important;
  flex-shrink: 0;
}

/* Glow Effect Styles */
.glow-effect {
  position: relative;
  --mouse-x: 0;
  --mouse-y: 0;
}

.glow-container {
  pointer-events: none;
  position: absolute;
  inset: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.glow-blur {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--glow-color);
  filter: blur(40px);
  opacity: 0.5;
  transform: translate(calc(var(--mouse-x) - 75px), calc(var(--mouse-y) - 75px));
}

.glow-effect:hover .glow-container {
  opacity: 1;
}

/* Event Card */
.event-card-wrapper {
  width: 70px;
  height: 70px;
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--event-color);
  box-shadow: 0 0 10px rgba(255, 102, 51, 0.2);
  line-height: 1.3;
}

.event-card-info-primary {
  font-size: 24px;
  font-weight: 700;
  color: var(--event-color);
  margin: 0;
  text-shadow: 0 0 5px rgba(255, 102, 51, 0.3);
}

.event-card-info-secondary {
  font-size: 12px;
  color: var(--text-secondary);
  margin: 0;
}

.block-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 4px;
}

.block-description {
  font-size: 13px;
  color: var(--text-secondary);
  margin: 0;
}

.block-description.sold-out {
  color: var(--event-color);
  font-weight: 500;
}

/* Bio Modal */
.bio-modal {
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 24px;
  color: var(--text-primary);
  max-width: 400px;
  width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

.bio-modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, var(--accent-blue), var(--accent-purple), var(--accent-pink));
}

.bio-modal::backdrop {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.bio-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.bio-modal-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(90deg, var(--accent-blue), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bio-modal-close-button {
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 5px;
  border-radius: 50%;
}

.bio-modal-close-button:hover {
  color: var(--text-primary);
  background: rgba(255, 255, 255, 0.1);
}

.profile-image-upload {
  width: 120px;
  height: 120px;
  margin: 0 auto 24px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background: var(--card-background);
  border: 2px dashed var(--border-color);
  overflow: hidden;
  transition: all 0.3s ease;
}

.profile-image-upload:hover {
  border-color: var(--accent-blue);
}

.profile-image-upload.drag-active {
  border-color: var(--accent-blue);
  box-shadow: 0 0 20px rgba(0, 204, 255, 0.3);
}

.profile-image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: bold;
  color: var(--text-secondary);
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: var(--text-primary);
  gap: 8px;
}

.profile-image-upload:hover .profile-image-overlay {
  opacity: 1;
}

.bio-form-group {
  margin-bottom: 20px;
}

.bio-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-secondary);
}

.bio-input,
.bio-form-group textarea {
  width: 100%;
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-primary);
  font-size: 14px;
  transition: all 0.3s ease;
}

.bio-input:focus,
.bio-form-group textarea:focus {
  outline: none;
  border-color: var(--accent-blue);
  box-shadow: 0 0 0 2px rgba(0, 204, 255, 0.1);
}

.bio-character-count {
  text-align: right;
  font-size: 12px;
  margin-top: 4px;
  color: var(--text-secondary);
}

.bio-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.bio-cancel-button,
.bio-save-button {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bio-cancel-button {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
}

.bio-cancel-button:hover {
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-primary);
}

.bio-save-button {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  border: none;
  color: white;
}

.bio-save-button:hover {
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.4);
  transform: translateY(-2px);
}

.bio-save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Contact Form */
.image-wrapper-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-wrapper-center-desktop {
  flex-direction: row;
}

.content-wrapper-center-desktop,
.content-wrapper-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  justify-content: center;
  margin-left: 15px;
}

.text-center {
  margin: 0;
  color: var(--text-primary);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .template-builder {
    grid-template-columns: 1fr;
  }

  .preview-pane {
    margin-top: 20px;
  }
}



/* Delete Modal */
.delete-modal {
  background: var(--card-background);
  border: 1px solid #ff3366;
  border-radius: 12px;
  padding: 24px;
  color: var(--text-primary);
  box-shadow: 0 0 20px rgba(255, 51, 102, 0.2);
}

/* Drag and Drop Styling */
.blocks-container.dragging-over {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 10px;
}

.blocks-container .react-beautiful-dnd-placeholder {
  background: rgba(255, 255, 255, 0.1);
  border: 2px dashed var(--border-color);
  border-radius: 10px;
  min-height: 80px;
  margin-bottom: 15px;
}

/* Social Modal */
.social-modal {
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  color: var(--text-primary);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.social-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}

.social-platform-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: var(--text-primary);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.social-platform-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.social-username-input {
  width: 100%;
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-primary);
  font-size: 14px;
  transition: all 0.3s ease;
}

.social-username-input:focus {
  outline: none;
  border-color: var(--accent-blue);
  box-shadow: 0 0 0 2px rgba(0, 204, 255, 0.1);
}

.social-input-example {
  font-size: 12px;
  color: var(--text-secondary);
  margin-top: 4px;
}

.social-modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 0 16px 16px;
}

.social-button-group {
  display: flex;
  gap: 10px;
}

.social-save-button,
.social-cancel-button,
.social-delete-button {
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.social-save-button {
  background: linear-gradient(135deg, var(--accent-blue), var(--accent-purple));
  border: none;
  color: white;
}

.social-save-button:hover {
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.4);
  transform: translateY(-2px);
}

.social-cancel-button {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
}

.social-cancel-button:hover {
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-primary);
}

.social-delete-button {
  background: rgba(255, 51, 102, 0.2);
  border: 1px solid #ff3366;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
}

.social-delete-button:hover {
  box-shadow: 0 0 15px rgba(255, 51, 102, 0.3);
  transform: translateY(-2px);
}

/* Platform-specific social icons */
.social-icon-button[data-platform="instagram"] {
  border-color: #E1306C;
  color: #E1306C;
}

.social-icon-button[data-platform="facebook"] {
  border-color: #1877F2;
  color: #1877F2;
}

.social-icon-button[data-platform="youtube"] {
  border-color: #FF0000;
  color: #FF0000;
}

.social-icon-button[data-platform="twitter"] {
  border-color: #1DA1F2;
  color: #1DA1F2;
}

.social-icon-button[data-platform="spotify"] {
  border-color: #1DB954;
  color: #1DB954;
}

.social-icon-button[data-platform="tiktok"] {
  border-color: #ffffff;
  color: #ffffff;
}

.social-icon-button[data-platform="linkedin"] {
  border-color: #0a66c2;
  color: #0a66c2;
}

.social-icon-button[data-platform="github"] {
  border-color: #333;
  color: #333;
}

.social-icon-button[data-platform="twitch"] {
  border-color: #9146ff;
  color: #9146ff;
}

.social-icon-button[data-platform="spotify"] {
  border-color: #1db954;
  color: #1db954;
}

.social-icon-button[data-platform="threads"] {
  border-color: linear-gradient(45deg, #000000, #1c1c1c);
  color: linear-gradient(45deg, #000000, #1c1c1c);
}

.social-icon-button[data-platform="whatsapp"] {
  border-color: #25d366;
  color: #25d366;
}

.social-icon-button[data-platform="snapchat"] {
  border-color: #fffc00;
  color: #fffc00;
}

.social-icon-button[data-platform="email"] {
  border-color: #ea4335;
  color: #ea4335;
}

.social-icon-button[data-platform="discord"] {
  border-color: #5865f2;
  color: #5865f2;
}

.social-icon-button[data-platform="telegram"] {
  border-color: #26a5e4;
  color: #26a5e4;
}

/* Separator styling */
.ant-separator {
  background: linear-gradient(90deg, transparent, var(--border-color), transparent);
  height: 1px;
  margin: 15px 0;
}

.preview-contact .block-card {
  display: flex;
}

.preview-contact:has(.image-wrapper-center) {
  margin: 0 auto;
}

.preview-contact-desktop:has(.image-wrapper-center) {
  margin: 0;
}

.social-modal-backdrop {
   position: fixed;
   inset: 0;
   background: rgba(0, 0, 0, 0.7);
   backdrop-filter: blur(8px);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 50;
 }
 
 .social-modal {
   background: var(--card-background);
   border: 1px solid var(--border-color);
   border-radius: 12px;
   width: 90%;
   max-width: 400px;
   color: var(--text-color);
   box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
   overflow: hidden;
   max-height: 550px;
   overflow-y: scroll;
 }
 
 .social-modal-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 16px;
   border-bottom: 1px solid var(--border-color);
 }
 
 .social-platform-header {
   display: flex;
   align-items: center;
   gap: 12px;
 }
 
 .social-modal-back,
 .social-modal-close {
   width: 32px;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   border: none;
   background: transparent;
   color: var(--text-color);
   cursor: pointer;
   transition: all 0.2s ease;
 }
 
 .social-modal-back:hover,
 .social-modal-close:hover {
   background: var(--button-hover-bg);
 }
 
 .social-modal-title {
   font-size: 1.25rem;
   font-weight: 600;
   margin: 0;
 }
 
 .social-modal-content {
   padding: 16px;
 }
 
 .social-search-container {
   position: relative;
   margin-bottom: 16px;
 }
 
 .social-search-icon {
   position: absolute;
   left: 12px;
   top: 50%;
   transform: translateY(-50%);
   width: 16px;
   height: 16px;
   color: var(--text-muted);
 }
 
 .social-search-input {
   width: 100%;
   padding: 12px 12px 12px 36px;
   background: var(--card-background);
   border: 1px solid var(--border-color);
   border-radius: 8px;
   color: var(--text-color);
   font-size: 0.9rem;
 }
 
 .social-search-input:focus {
   outline: none;
   border-color: var(--accent-blue);
   box-shadow: 0 0 0 2px rgba(0, 188, 212, 0.1);
 }
 
 .social-platforms-list {
   display: flex;
   flex-direction: column;
   gap: 4px;
 }
 
 .social-platform-item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 12px;
   background: transparent;
   border: none;
   border-radius: 8px;
   font-size: 1rem;
   cursor: pointer;
   transition: all 0.2s ease;
   width: 100%;
 }
 
 .social-platform-item:hover {
   background: var(--button-hover-bg);
 }
 
 .social-platform-info {
   display: flex;
   align-items: center;
   gap: 12px;
 }
 
 .social-platform-icon {
   width: 24px;
   height: 24px;
 }
 
 .social-platform-arrow {
   width: 16px;
   height: 16px;
   color: var(--text-muted);
 }
 
 .social-input-container {
   display: flex;
   flex-direction: column;
   gap: 16px;
 }
 
 .social-input-wrapper {
   position: relative;
 }
 
 .social-username-input {
   width: 100%;
   padding: 12px;
   background: var(--card-background);
   border: 1px solid var(--border-color);
   border-radius: 8px;
   font-size: 1rem;
 }
 
 .social-username-input:focus {
   outline: none;
   border-color: var(--accent-blue);
   box-shadow: 0 0 0 2px rgba(0, 188, 212, 0.1);
 }
 
 .social-input-example {
   font-size: 0.875rem;
   color: var(--text-muted);
   margin: 0;
 }
 
 .social-modal-actions {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 24px;
 }
 
 .social-button-group {
   display: flex;
   gap: 8px;
 }
 
 .social-save-button,
 .social-cancel-button {
   padding: 8px 16px;
   border-radius: 6px;
   font-size: 0.9rem;
   cursor: pointer;
   transition: all 0.2s ease;
 }
 
 .social-save-button {
   background: var(--accent-blue);
   border: none;
   color: white;
 }
 
 .social-save-button:hover {
   opacity: 0.9;
 }
 
 .social-save-button:disabled {
   opacity: 0.5;
   cursor: not-allowed;
 }
 
 .social-cancel-button {
   background: transparent;
   border: 1px solid var(--border-color);
   color: var(--text-color);
 }
 
 .social-cancel-button:hover {
   background: var(--button-hover-bg);
 }
 
 .social-delete-button {
   gap: 8px;
   padding: 8px 16px;
   background: #ff4d4f;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   transition: all 0.2s ease;
   border-radius: 6px;
 }
 
 .social-delete-button:hover {
   opacity: 0.9;
 }
 
 .social-delete-icon {
   width: 16px;
   height: 16px;
 }
 
 /* Social Icons Styles */
 .social-icons-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 8px;
   margin-top: 16px;
   flex-wrap: wrap;
 }
 
 .social-icon-button {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 35px;
   height: 35px;
   border-radius: 50%;
   background: transparent;
   border: 2px solid var(--border-color);
   color: var(--text-color);
   transition: all 0.2s ease;
   cursor: pointer;
 }
 
 .social-icon-button:hover {
   border-color: var(--accent-blue);
   color: var(--accent-blue);
 }
 
 .social-icon {
   width: 20px;
   height: 20px;
 }
 
 .add-social-button {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 35px;
   height: 35px;
   border-radius: 50%;
   background: transparent;
   border: 2px dashed var(--border-color);
   color: var(--text-color);
   transition: all 0.2s ease;
   cursor: pointer;
 }

 .add-social-button:hover {
   border-color: var(--accent-blue);
   color: var(--accent-blue);
 }
 
 .social-platform-added {
   font-size: 0.8rem;
   color: var(--accent-blue);
   margin-left: auto;
   padding-left: 8px;
 }

 /* Add these styles to your existing CSS file */

/* Header actions container to arrange elements in a row */
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Theme selector dropdown styles */
.theme-dropdown {
  position: relative;
}

/* Theme option styling */
.theme-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.theme-option:hover {
  background: rgba(255, 255, 255, 0.05);
}

.theme-option.active {
  background: rgba(0, 204, 255, 0.1);
}

/* Phone content theme-specific styles */


.phone-content[data-theme="tropical-paradise"] {
  background: #0d2a35
    url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2300d4bb' stroke-width='0.3' fill='none' d='M15 0v60M30 0v60M45 0v60M0 15h60M0 30h60M0 45h60'/%3E%3C/svg%3E");
}

.phone-content[data-theme="midnight-aurora"] {
  background: #0f0f1b linear-gradient(to bottom, rgba(123, 97, 255, 0.1), transparent);
}

.phone-content[data-theme="geometric-pulse"] {
  background: #17141f
    url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff5370' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}

.phone-content[data-theme="liquid-gradient"] {
  background: #16151d linear-gradient(135deg, rgba(31, 222, 219, 0.05), rgba(255, 105, 180, 0.05));
}


/* Phone content theme-specific styles */
.phone-content[data-theme="cyberpunk"] {
  background: #0f0f13;
  background-image: radial-gradient(circle at 10% 10%, rgba(153, 51, 255, 0.1) 0%, transparent 30%),
    radial-gradient(circle at 90% 90%, rgba(0, 204, 255, 0.1) 0%, transparent 30%),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: inset 0 0 30px rgba(153, 51, 255, 0.1);
}

.phone-content[data-theme="neon-city"] {
  background: #0d0d12;
  background-image: linear-gradient(0deg, rgba(0, 255, 136, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 255, 136, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  box-shadow: inset 0 0 30px rgba(0, 255, 136, 0.1);
}

.phone-content[data-theme="sunset-beach"] {
  background: #1a1a25;
  background-image: linear-gradient(180deg, rgba(255, 107, 53, 0.2) 0%, rgba(255, 107, 53, 0) 50%),
    linear-gradient(0deg, rgba(255, 158, 122, 0.1) 0%, rgba(255, 158, 122, 0) 70%);
  box-shadow: inset 0 0 30px rgba(255, 107, 53, 0.1);
}

.phone-content[data-theme="midnight-ocean"] {
  background: #0a1525;
  background-image: radial-gradient(circle at 50% 100%, rgba(0, 119, 204, 0.2) 0%, transparent 60%),
    linear-gradient(0deg, rgba(0, 170, 204, 0.1) 0%, rgba(0, 170, 204, 0) 70%);
  box-shadow: inset 0 0 30px rgba(0, 119, 204, 0.1);
}

.phone-content[data-theme="aurora-borealis"] {
  background: #0f0f1b;
  background-image: linear-gradient(135deg, rgba(123, 97, 255, 0.1) 0%, transparent 50%),
    linear-gradient(225deg, rgba(97, 255, 142, 0.1) 0%, transparent 50%),
    linear-gradient(315deg, rgba(255, 97, 224, 0.1) 0%, transparent 50%);
  box-shadow: inset 0 0 30px rgba(123, 97, 255, 0.1);
}

.phone-content[data-theme="digital-wave"] {
  background: #0a1929;
  background-image: linear-gradient(0deg, rgba(64, 169, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(64, 169, 255, 0.05) 1px, transparent 1px);
  background-size: 30px 30px;
  box-shadow: inset 0 0 30px rgba(64, 169, 255, 0.1);
}

.phone-content[data-theme="neon-sunset"] {
  background: #17141f;
  background-image: linear-gradient(180deg, rgba(255, 83, 112, 0.1) 0%, transparent 50%),
    linear-gradient(0deg, rgba(255, 158, 122, 0.1) 0%, transparent 70%);
  box-shadow: inset 0 0 30px rgba(255, 83, 112, 0.1);
}

.phone-content[data-theme="tropical-night"] {
  background: #0d2a35;
  background-image: radial-gradient(circle at 20% 80%, rgba(0, 212, 187, 0.1) 0%, transparent 40%),
    radial-gradient(circle at 80% 20%, rgba(123, 255, 140, 0.1) 0%, transparent 40%);
  box-shadow: inset 0 0 30px rgba(0, 212, 187, 0.1);
}

.phone-content[data-theme="neon-particles"] {
  background: #11001c;
  background-image: radial-gradient(circle at 30% 30%, rgba(225, 0, 255, 0.1) 0%, transparent 20%),
    radial-gradient(circle at 70% 70%, rgba(0, 255, 247, 0.1) 0%, transparent 20%),
    radial-gradient(circle at 30% 70%, rgba(255, 0, 151, 0.1) 0%, transparent 20%),
    radial-gradient(circle at 70% 30%, rgba(123, 255, 0, 0.1) 0%, transparent 20%);
  box-shadow: inset 0 0 30px rgba(225, 0, 255, 0.1);
}

.phone-content[data-theme="cosmic-blend"] {
  background: #0f0720;
  background-image: radial-gradient(circle at 20% 20%, rgba(207, 35, 207, 0.1) 0%, transparent 30%),
    radial-gradient(circle at 80% 80%, rgba(35, 185, 207, 0.1) 0%, transparent 30%);
  box-shadow: inset 0 0 30px rgba(207, 35, 207, 0.1);
}

/* Ensure smooth transitions when changing themes */
.phone-content * {
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

