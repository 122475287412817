input[name="soundCloudUrl"]::placeholder {
    color: #bbbbbb;
}

input[name="soundCloudUrl"] {
    border-color: #000000;
}

.modal-header span {
    font-size: 2.0em;
    color: #cccccc;
    font-weight: 100;
    position: absolute;
    right: -40px;
    top: -50px;
}

div.modal-content {
    padding: 50px;
    background-color: #7a7a7a;
}