/* Settings v2 CSS*/
.settings-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.settings-title  {
    color: white;
    margin: 1 rem 0;
    /* padding: 20px; */
    font-size: 1.5rem;
}

.settings-navbar {
    /* padding: 10px 40px;
    margin: 5px;
    border-radius: 5px; */
    width: 100%;
    padding: 1 rem 0;
}

.settings-navbar-list{
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 20px;
    padding: 0;
    margin: 0; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 1rem;
    padding: 0;
    margin: 0;
}
/* .container {
  width: 90%; 
  margin: 0 auto; 
  border: 1px solid red;
} */
.settings-content   {
    text-align: center;
    display: flex;
    justify-content: center;
    max-width: 800px; 
    min-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    
  }

.settings-content--account h3{
  width: 18.75rem;
  margin-top: 10px;
  font-family: 'Arial', sans-serif; 
  font-size: 18px; 
}

.settings-tab  {
    color: white;
    font-size: 24px;

}

.button-group {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.nav-item {
  margin-right: 20px;
  font-weight: bold;
  cursor: pointer;
}

.nav-item.active {
  border-bottom: 2px solid black;
}