@media only screen and (min-width: 992px) {
    .topEngagement {
        margin-left: 10px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 992px) {
    .topEngagement {
        margin-top: 20px;
    }
} 