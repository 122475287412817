.add-link-dialog {
  background-color: #0d0d0d;
  color: #e0e0e0;
  border: 1px solid #00ffff;
  border-radius: 8px;
  padding: 24px;
  max-width: 500px;
  width: 100%;
  font-family: "Inter", sans-serif;
  box-shadow: 0 0 20px rgba(61, 61, 92, 0.3);
}

.add-link-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
}

.add-link-dialog h2 {
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #00ffff;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.add-link-form-group {
  margin-bottom: 15px;
  background-color: #0d0d0d;
}

.add-link-form-group label {
  display: block;
  margin-bottom: 5px;
  color: #00ffff;
}

.add-link-form-group input[type="text"] {
  width: 100%;
  padding: 10px;
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  color: #e0e0e0;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.add-link-form-group input[type="text"]:focus {
  border-color: #00ffff;
  box-shadow: 0 0 0 2px rgba(0, 255, 255, 0.2);
  outline: none;
}

.add-link-form-group input[type="text"]::placeholder {
  color: #6c6c8b;
}

.add-link-image-upload-label {
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
  border: 2px dashed #3d3d5c;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.add-link-image-upload-label:hover {
  border-color: #00ffff;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
}

.add-link-upload-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
  color: #3d3d5c;
}

.add-link-uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-link-hidden-input {
  display: none;
}

.add-link-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.add-link-cancel-button,
.add-link-save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: none;
}

.add-link-cancel-button {
  background-color: #2a2a3a;
  color: #e0e0e0;
}

.add-link-save-button {
  background-color: #00ffff;
  color: #0f0f1a;
}

.add-link-cancel-button:hover,
.add-link-save-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.add-link-error {
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 5px;
}

.add-link-dialog-title {
  font-size: 22px;
  margin: 0 !important;
}

.add-link-dialog-header {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  position: relative;
}

.close-button {
  position: absolute;
  right: 0;
}

.add-link-dialog-x {
  width: 24px;
  height: 24px;
}
